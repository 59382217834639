import {
  getPaginated,
  PaginatedRequest,
  PaginatedResponse,
} from "@/application/api/getPaginated";
import { AxiosResponse } from "axios";
import mapIndexResponseErrors from "@/application/util/mapIndexResponseErrors";

export interface ReturnIndexItem {
  id: number;
  receiptDate: string;
  productCode: string;
  productName?: string;
  stockStatus: string;
  batchNumber: string;
  bestBeforeDate: null | string;
  receivedQuantity: number;
  unit: string;
  orderNumber: null | string;
  reasonOfReturn: null | string;
}

export const clientReturnIndex = async (
  data: PaginatedRequest,
  clientId: number
): Promise<AxiosResponse<PaginatedResponse<ReturnIndexItem[]>>> => {
  const response = await getPaginated(`client/${clientId}/return`, data);
  response.data.data = mapIndexResponseErrors(response.data.data);

  return response;
};
